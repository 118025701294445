<template>
  <v-container fluid class="pa-0">
    <side-menu :main-page="$route.name" />

    <div class="ma-0">
      <v-toolbar flat color="grey lighten-3" style="padding: 0 139px 0 395px">
        <input
          style="display: none"
          ref="metainput"
          type="file"
          accept=".csv"
          @change="onChangeMetaCSVFile()"
        />
        <v-btn outlined @click="handleUploadMetaCSVFile">
          {{ getWords("create_read_meta_csv") }}
        </v-btn>

        <v-spacer />
        <!-- <v-avatar
          v-for="i in 3"
          :key="i"
          class="ml-n2"
          color="primary"
          size="40"
        >
          v
        </v-avatar> -->

        <v-dialog v-model="dialog" scrollable max-width="550px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="ml-2" light outlined rounded
              >+ {{ getEditableUsers }}</v-btn
            >
          </template>
          <v-card>
            <v-card-actions>
              <v-card-title>{{ getWords("create_add_member") }}</v-card-title>
              <v-spacer></v-spacer>
              <v-btn color="#C6C6C6" text small @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-text>
              <v-text-field
                filled
                rounded
                dense
                prepend-inner-icon="mdi-magnify"
              />
            </v-card-text>
            <v-card-text style="height: 200px">
              <div
                v-for="user in getUserList"
                :key="user.uid"
                class="d-flex align-center mb-3"
              >
                <v-avatar color="indigo" size="45" class="mr-3">
                  <v-icon dark>mdi-account-circle</v-icon>
                </v-avatar>
                <p class="ma-0">{{ user.name }}</p>
                <v-spacer></v-spacer>
                <p v-if="user.creator == true" class="mr-5">
                  {{ getWords("create_member_creator") }}
                </p>
                <v-btn
                  v-else-if="user.member == true"
                  color="#C6C6C6"
                  outlined
                  @click="editorsChange(user)"
                  >{{ getWords("create_member_invite") }}</v-btn
                >
                <v-btn
                  v-else
                  color="#6C6C6C"
                  outlined
                  @click="editorsChange(user)"
                  >{{ getWords("create_member_invite") }}</v-btn
                >
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#6C6C6C" outlined @click="dialog = false">
                {{ getWords("common_button_back") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

      <div style="padding: 0 139px 150px 395px">
        <!--
        <div class="d-flex justify-space-between mt-13">
          <div>
            <v-avatar class="mr-5" color="primary" size="48"> v </v-avatar>
            <a href="">変更</a>
          </div>
        </div>
        -->

        <div class="d-flex align-center">
          <v-col cols="5" class="pa-0">
            <v-select
              class="mt-8"
              label="STATUS"
              :items="app_status.items"
              mandatory
              v-model="app_status.selected"
            />
          </v-col>
        </div>

        <div v-if="isNew != true" class="d-flex align-center">
          <v-col cols="5" class="pa-0">
            <v-select
              class="mt-0"
              :label="getWords('meta_downloadcsv')"
              :items="exports.items"
              item-text="name"
              mandatory
              item-value="url"
              v-model="exports.selected"
            />
          </v-col>
          <v-btn class="ml-2" light outlined @click="metaDownloadBtnPressed">
            {{ getWords("common_button_download") }}
          </v-btn>
        </div>

        <v-form ref="form">
          <section class="mt-2">
            <div class="my-6">
              <p class="my-2 text-h6">{{ getWords("create_ns_title") }}</p>
              <p class="ma-0 grey--text text-darken-4">
                {{ getWords("create_ns_message1") }}
              </p>
              <v-checkbox class="mt-1 mb-1 pa-0" v-model="namespace.use">
                <template v-slot:label class="ma-0 pa-0">
                  <div>{{ getWords("create_ns_message2") }}</div>
                </template>
              </v-checkbox>
              <v-col cols="5" class="ma-0 pa-0">
                <v-select
                  class="pa-0"
                  :items="getNameSpaces"
                  item-text="path"
                  mandatory
                  v-model="namespace.path"
                  :disabled="namespace.use == false"
                />
              </v-col>
              <p v-if="namespace.use" class="ma-0 grey--text text-darken-4">
                {{ apiHost }}/ns/{{ namespace.path }}
              </p>
            </div>

            <p class="mt-6 mb-0 text-h6">{{ getWords("create_bi_title") }}</p>
            <v-col cols="3" class="pa-0">
              <v-select
                class="mt-4"
                label="Language"
                :items="languages"
                mandatory
                v-model="sLanguage"
              />
            </v-col>
            <div class="d-flex align-center">
              <!-- <h2 class="text-h4 mr-4">
                a-loc- Location Ontology (ArCo network)
              </h2> -->
              <v-text-field
                v-if="sLanguage == languages[0]"
                label="TITLE(ja)"
                class="mr-4"
                v-model="base.title.ja"
                :rules="[rules.required]"
                :disabled="ttlCsvObj.meta.length > 0 && isNew"
              ></v-text-field>
              <v-text-field
                v-if="sLanguage == languages[1]"
                label="TITLE(en)"
                class="mr-4"
                v-model="base.title.en"
                :rules="[rules.required]"
                :disabled="ttlCsvObj.meta.length > 0 && isNew"
              ></v-text-field>
            </div>

            <div class="ma-0">
              <v-textarea
                v-if="sLanguage == languages[0]"
                label="DESCRIPTION(ja)"
                v-model="base.description.ja"
                :rules="[rules.required]"
                :disabled="ttlCsvObj.meta.length > 0 && isNew"
              ></v-textarea>
              <v-textarea
                v-if="sLanguage == languages[1]"
                label="DESCRIPTION(en)"
                v-model="base.description.en"
                :rules="[rules.required]"
                :disabled="ttlCsvObj.meta.length > 0 && isNew"
              ></v-textarea>
            </div>

            <v-text-field
              class="mt-5"
              :label="getWords('name_meta_published2')"
              hide-details="auto"
              v-model="base.published"
              :rules="[rules.required]"
              :disabled="ttlCsvObj.meta.length > 0 && isNew"
            ></v-text-field>

            <v-text-field
              class="mt-5"
              :label="getWords('name_meta_license')"
              hide-details="auto"
              v-model="base.license"
              :rules="[rules.required]"
            ></v-text-field>

            <v-text-field
              class="mt-5"
              :label="getWords('name_meta_right')"
              hide-details="auto"
              v-model="base.rights"
              :rules="[rules.required]"
            ></v-text-field>

            <v-text-field
              class="mt-5"
              :label="getWords('name_meta_change')"
              hide-details="auto"
              v-model="base.note"
            ></v-text-field>

            <div class="mt-12">
              <p class="ma-0 text-h6">{{ getWords("name_meta_contact2") }}</p>
              <v-text-field
                label="NAME"
                hide-details="auto"
                v-model="contacts.name"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                label="URL"
                hide-details="auto"
                v-model="contacts.url"
              ></v-text-field>
              <v-text-field
                label="E-MAIL"
                hide-details="auto"
                v-model="contacts.email"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </div>

            <v-combobox
              v-model="base.categories"
              class="mt-5"
              :items="categories"
              chips
              :label="getWords('name_meta_category')"
              multiple
            />

            <v-combobox
              class="pa-0"
              chips
              :label="getWords('name_meta_tag')"
              multiple
              v-model="base.tags"
            />

            <div class="mt-12">
              <p class="mx-0 mt-0 mb-4 text-h6">
                {{ getWords("create_dist_title") }}
              </p>
              <p
                v-if="distributions.length == 0"
                class="red--text text-subtitle-2 font-weight-bold"
              >
                {{ getWords("create_dist_message1") }}
              </p>

              <div v-if="distributions.length != 0" class="dist_divider"></div>
              <div v-if="distributions.length != 0" class="dist_divider"></div>
              <div v-for="(v, i) in distributions" :key="i" class="pt-2 pb-4">
                <div v-if="i != 0 && i != 1" class="dist_divider2 my-3"></div>
                <div class="pt-5 pb-2">
                  <div style="position: relative">
                    <v-btn
                      dark
                      width="24"
                      height="24"
                      fab
                      depressed
                      color="#5C5C5C"
                      style="
                        position: absolute;
                        top: -8px;
                        left: -8px;
                        z-index: 1;
                      "
                      @click="removeDistribution(i)"
                    >
                      <v-icon dark small>mdi-minus</v-icon>
                    </v-btn>
                    <v-img
                      :src="require('@/assets/file.png')"
                      contain
                      width="44"
                      height="51"
                    >
                    </v-img>
                  </div>
                  <v-select :label="v.format" disabled></v-select>
                </div>
                <v-radio-group v-model="v.type" mandatory row>
                  <v-radio
                    :label="getWords('create_dist_message2')"
                    value="url"
                  />
                  <v-radio
                    :label="getWords('create_dist_message3')"
                    value="file"
                  />
                  <v-radio
                    v-if="ttlCsvObj.meta.length > 0"
                    :label="getWords('create_dist_message4')"
                    value="meta"
                  />
                </v-radio-group>
                <div v-if="v.type === 'file'">
                  <v-file-input
                    label="File input"
                    outlined
                    dense
                    @change="onChangeFileInput(i, $event)"
                  />
                  <p>
                    {{ getWords("create_dist_message5") }}<br />
                    {{ getWords("create_dist_message6") }}（CSV）
                  </p>
                  <!-- <v-btn outlined>CANCEL</v-btn> -->
                </div>
                <div v-else-if="v.type === 'meta'">
                  <v-file-input
                    label="File input"
                    outlined
                    dense
                    accept=".csv"
                    @change="onChangeFileInput(i, $event)"
                  />
                  <p v-if="v.file.name != ''">{{ v.file.name }}</p>
                  <p>
                    {{ getWords("create_dist_message7") }}<br />
                    {{ getWords("create_dist_message8") }}
                  </p>
                  <v-btn outlined>{{ getWords("common_button_cancel") }}</v-btn>
                </div>
                <div>
                  <v-text-field
                    v-if="v.type === 'url'"
                    label="DOWNLOAD URL"
                    hide-details="auto"
                    v-model="v.downloadURL"
                    :rules="[rules.required]"
                  >
                    <template v-slot:append-outer>
                      <v-icon
                        v-if="v.downloadURL.length > 10 && !isNew"
                        @click="distDownload(v)"
                      >
                        mdi-download
                      </v-icon>
                    </template>
                  </v-text-field>
                  <v-text-field
                    label="TITLE"
                    hide-details="auto"
                    v-model="v.title"
                    :rules="[rules.required]"
                  />
                  <v-text-field
                    label="DESCRIPTION"
                    hide-details="auto"
                    v-model="v.description"
                  />
                </div>
                <p v-if="i == 0" class="text-caption mt-4 mb-4 font-weight-bold">
                  {{ getWords("create_dist_message10") }}
                </p>
                <div v-if="i == 0" class="dist_divider"></div>
                <div v-if="i == 0" class="dist_divider"></div>
              </div>
              <p class="mt-8 text-subtitle-1">
                {{ getWords("create_dist_add") }}
              </p>
              <v-row no-gutters class="ma-0">
                <v-col cols="5">
                  <v-select
                    class="mt-0"
                    :items="formats"
                    :label="getWords('create_dist_message9')"
                    v-model="sFormat"
                  />
                </v-col>
                <v-col cols="6" class="pl-3">
                  <v-btn
                    @click="appendDistribution"
                    class="mt-3"
                    outlined
                    :disabled="sFormat === ''"
                  >
                    {{ getWords("common_button_add") }}
                  </v-btn>
                </v-col>
              </v-row>
              <p class="text-caption font-weight-bold">
                {{ getWords("create_dist_message11") }}
              </p>
            </div>
          </section>
        </v-form>
        <div
          class="d-flex justify-center mt-9 pt-8"
          style="border-top: 6px solid #c6c6c6"
        >
          <v-btn outlined>{{ getWords("common_button_cancel") }}</v-btn>
          <v-btn
            class="ml-4"
            outlined
            :loading="loading"
            :disabled="loading"
            color="#8F5F98"
            @click="saveBtnPressed"
          >
            {{ getWords("common_button_save") }}
          </v-btn>
        </div>

        <v-sheet
          v-if="$route.name !== 'Create'"
          class="d-flex justify-lg-space-between align-center pa-4 mt-8"
          rounded
          outlined
        >
          <div>
            <p>
              {{ getWords("name_meta_created") }}: {{ getCreatedTime }}
            </p>
            <p class="ma-0">
              {{ getWords("name_meta_modified") }}: {{ getUpdatedTime }}
            </p>
          </div>
          <v-btn @click="dialog02 = true" class="ml-auto">
            {{ getWords("create_history_title") }}
          </v-btn>
          <v-dialog v-model="dialog02" scrollable max-width="550px">
            <v-card>
              <v-card-actions>
                <v-card-title>
                  {{ getWords("create_history_title") }}
                </v-card-title>
                <v-spacer></v-spacer>
                <v-btn color="#C6C6C6" text small @click="dialog02 = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-text style="height: 200px">
                <div
                  v-for="(history, i) in getHistories"
                  :key="i"
                  class="d-flex align-center mb-3"
                >
                  <v-avatar color="indigo" size="45" class="mr-3">
                    <v-icon dark>mdi-account-circle</v-icon>
                  </v-avatar>
                  <p class="ma-0">{{ history.name }}</p>
                  <v-spacer></v-spacer>
                  <p class="text-right">
                    {{ getWords("create_history_message1") }}<br />
                    {{ getDate(history.date) }}
                  </p>
                </div>
              </v-card-text>
              <div class="text-center pb-3">
                <v-btn outlined @click="dialog02 = false">
                  {{ getWords("common_button_close") }}
                </v-btn>
              </div>
            </v-card>
          </v-dialog>
        </v-sheet>

        <v-snackbar v-model="snackbar">
          {{ getWords("create_snack_message") }}
          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
              {{ getWords("common_button_close") }}
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import SideMenu from "../../components/SideMenu";
import {
  db,
  // fb,
  getUserID,
  fileUploadToRevision,
  fileUploadToRevisionOfDisribution,
  getUserVocabulariesList,
  getTargetVocabulary,
  getVocabularyHistories,
  getUserList,
  loadMetaObjFromUrl,
  getAvailableNamespaces,
  getDistributionFile,
  FB_API_HOST,
  updateVocabularyStructure,
} from "../../plugins/firebase";
import {
  //  readUploadCSV,
  readLocalCSV,
  // readRemoteCSV,
  createJsonLd,
} from "../../plugins/csvtottl";
import VocabUtil from "../../plugins/vocabulary_utils";
import words from "../../assets/localewords.json";
const generateApiKey = require("generate-api-key");

export default {
  name: "create",
  data: () => ({
    dialog: false,
    dialog02: false,
    languages: ["日本語", "English"],
    categories: [
      "防災・災害",
      "医療・福祉",
      "治安",
      "交通",
      "観光",
      "文化・芸術",
      "環境",
      "その他",
    ],
    sLanguage: "日本語",
    base: VocabUtil.initMeta(),
    contacts: VocabUtil.initContacts(),
    app_metadata: VocabUtil.initAppMeta(),
    distributions: [],
    removeDistributions: [],
    csvFileObj: {
      meta: new Object(),
      vocab: new Object(),
    },
    ttlCsvObj: {
      meta: [],
      vocab: [],
    },
    isNew: true,
    exports: {
      items: [],
      selected: "",
    },
    app_status: {
      items: ["draft", "public"],
      selected: "draft",
    },
    rules: {
      required: (v) => (v && !!v) || "Required.",
      email: (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Invalid email",
    },
    formats: ["RDF", "OWL", "SKOS"],
    sFormat: "",
    noDistribution: true,
    users: [],
    editors: [],
    histories: [],
    snackbar: false,
    loader: null,
    loading: false,
    namespace: {
      use: false,
      path: "",
      list: [],
    },
    namespaceRefresh: true,
    apiHost: FB_API_HOST,
  }),
  components: { SideMenu },
  created() {
    // console.log("created: " + this.$route.name);
    this.initialize();
  },
  watch: {
    $route() {
      // console.log("watch: " + this.$route.name);
      this.initialize();
    },
    getEditParams: function (to, from) {
      if (to.id !== from.id) {
        this.initialize();
      }
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.login;
    },
    getLanguage() {
      return this.$store.state.language;
    },
    getCreatedTime() {
      //console.log(this.base.created);
      if (this.base.created instanceof Date) {
        return "";
      } else {
        return this.getDate(this.base.created);
      }
    },
    getUpdatedTime() {
      if (this.base.created instanceof Date) {
        return "";
      } else {
        return this.getDate(this.base.updated);
      }
    },
    getEditParams() {
      return this.$store.state.editParams;
    },
    getUserList() {
      if (this.users.length === 0) {
        this.callGetUserList();
      }
      return this.users;
    },
    getEditableUsers() {
      // console.log(JSON.stringify(this.users));
      const usrs = this.users.filter((user) => user.member || user.creator);
      return usrs.length;
    },
    getHistories() {
      if (this.isNew) {
        return [];
      }
      if (this.histories.length === 0) {
        this.callGetVocabularyHistories();
      }
      return this.histories;
    },
    getNameSpaces() {
      if (this.namespace.list.length === 0 && this.isLoggedIn && this.namespaceRefresh) {
        this.callGetAvailableNamespaces();
      }
      return this.namespace.list;
    },
  },
  methods: {
    initialize: async function () {
      this.sLanguage = "日本語";
      this.base = VocabUtil.initMeta();
      this.contacts = VocabUtil.initContacts();
      this.app_metadata = VocabUtil.initAppMeta();
      this.distributions = [];
      this.removeDistributions = [];
      this.noDistribution = true;
      this.editors = [];
      this.histories = [];
      this.csvFileObj = {
        meta: new Object(),
        vocab: new Object(),
      };
      this.ttlCsvObj = {
        meta: [],
        vocab: [],
      };
      this.sFormat = "";
      this.exports = {
        items: [],
        selected: "",
      };
      this.users = [];
      this.app_status.selected = "draft";
      this.namespace.list = [];
      this.namespace.path = "";
      this.namespace.use = false;
      this.namespaceRefresh = true;
      const params = this.getEditParams;
      if (this.$route.name === "Create") {
        this.isNew = true;
      } else {
        // console.log(this.$store.state.editParams);
        this.isNew = false;
        if (params.id === "") {
          this.$router.push({ name: "Type" }).catch((err) => console.log(err));
          return;
        }
        const targetVocab = await getTargetVocabulary(params.id);
        const parseData = VocabUtil.convertVocabularyToObject(targetVocab);
        this.base = parseData.base;
        this.contacts = parseData.contacts;
        this.app_metadata = parseData.app_meta;
        if (this.app_metadata.namespace && this.app_metadata.namespace != "") {
          this.namespace.path = this.app_metadata.namespace;
          this.namespace.use = true;
          // このpathはこのvocabularyに使われているので、使用可能namespaceリストに出てこない
          if (this.namespace.list.filter((item) => item.path === this.namespace.path).length === 0) {
            this.namespace.list.push({
              path: this.namespace.path,
            });
          }
        }
        this.distributions = parseData.distributions;
        this.noDistribution = this.distributions.length === 0;
        this.editors = this.app_metadata.users;
        this.app_status.selected = this.app_metadata.status;
        this.refreshExportableList();
        if (
          this.app_metadata.specializedObject &&
          this.app_metadata.specializedObject.meta_csv_url
        ) {
          const response = await loadMetaObjFromUrl(
            this.app_metadata.specializedObject.meta_csv_url
          );
          this.ttlCsvObj.meta = response.data;
        }
      }
      const self = this;
      setTimeout(function () {
        if (self.$refs.form) {
          self.$refs.form.validate();
        }
      }, 1500);
    },
    getWords(key) {
      let lang = this.getLanguage;
      if (lang !== "en" && lang !== "ja") {
        lang = "en";
      }
      const word = words[key] ? words[key][lang] : "";
      return word;
    },
    createDistribution: function (format) {
      const newDist = {
        title: "",
        description: "",
        downloadURL: "",
        type: "url",
        file: new Object(),
        format: format ? format : "SKOS",
      };
      return newDist;
    },
    refreshExportableList: function () {
      this.exports.items = [
        {
          name: "表示情報（簡易）のエクスポート",
          url: "export1",
        },
        {
          name: "表示情報（詳細）のエクスポート",
          url: "export2",
        },
      ];
      if (
        this.app_metadata.specializedObject &&
        this.app_metadata.specializedObject.meta_csv_url
      ) {
        this.exports.items.push({
          name: "メタデータの元CSV",
          url: this.app_metadata.specializedObject.meta_csv_url,
        });
      }
      const distItems = this.distributions
        .filter((item) => {
          return (
            item.metadata.specializedObject &&
            item.metadata.specializedObject.vocabulary_csv_url
          );
        })
        .map((item) => {
          return {
            name: "Vocabularyの元データCSV(" + item.title + ")",
            url: item.metadata.specializedObject.vocabulary_csv_url,
          };
        });
      Array.prototype.push.apply(this.exports.items, distItems);
    },
    metaDownloadBtnPressed: function () {
      // console.log(this.exports.selected);
      if (this.exports.selected === "") {
        return;
      }
      const link = document.createElement("a");
      if (this.exports.selected === "export1") {
        const blob = this.convertMetaToCSV();
        const fileName = "meta_" + this.$store.state.editParams.id + ".csv";
        this.downloadByATag(blob, fileName);
      } else if (this.exports.selected === "export2") {
        const blob = this.convertMetaToCSV2();
        const fileName = "meta_all_" + this.$store.state.editParams.id + ".csv";
        this.downloadByATag(blob, fileName);
      } else {
        link.href = this.exports.selected;
        link.click();
        link.remove();
      }
    },
    convertMetaToCSV: function () {
      const titleLine =
        "語彙の名称,語彙の英語名称,語彙の説明,語彙の英語説明,語彙の作成者";
      const dataLine =
        this.base.title.ja +
        "," +
        this.base.title.en +
        "," +
        '"' +
        this.base.description.ja +
        '"' +
        "," +
        '"' +
        this.base.description.en +
        '"' +
        "," +
        this.base.published;
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const final = titleLine + "\r\n" + dataLine;
      return new Blob([bom, final], {
        type: "text/csv",
      });
    },
    convertMetaToCSV2: function () {
      const titleLine =
        "語彙の名称,語彙の英語名称,語彙の説明,語彙の英語説明,語彙の作成者,ライセンス,権利,バージョン,カテゴリ,タグ,連絡先,E-mail,連絡先URL,作成日,更新日";
      const dataLine =
        this.base.title.ja +
        "," +
        this.base.title.en +
        "," +
        '"' +
        this.base.description.ja +
        '"' +
        "," +
        '"' +
        this.base.description.en +
        '"' +
        "," +
        this.base.published +
        "," +
        this.base.license +
        "," +
        this.base.rights +
        "," +
        this.base.note +
        "," +
        '"' +
        this.base.categories.join(" ") +
        '"' +
        "," +
        '"' +
        this.base.tags.join(" ") +
        '"' +
        "," +
        this.contacts.name +
        "," +
        this.contacts.email +
        "," +
        this.contacts.url +
        "," +
        this.getDate(this.base.created) +
        "," +
        this.getDate(this.base.updated);
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const final = titleLine + "\r\n" + dataLine;
      return new Blob([bom, final], {
        type: "text/csv",
      });
    },
    appendDistribution: function () {
      //this.distributions.push(this.createDistribution(this.sFormat));
      this.distributions.push(
        VocabUtil.initDistributionWithFileObj(this.sFormat)
      );
      this.sFormat = "";
      this.noDistribution = false;
    },
    removeDistribution: function (index) {
      const self = this;
      const newDists = [];
      this.distributions.forEach((item, i) => {
        if (i === index) {
          // アップデート時に削除collectionから削除する必要がある。idが存在する場合のみ
          if (item.metadata.id !== "") {
            self.removeDistributions.push(item);
          }
          if (item.type === "meta") {
            this.ttlCsvObj.vocab = [];
          }
        } else {
          newDists.push(item);
        }
      });
      this.distributions = newDists;
      this.noDistribution = this.distribution.length === 0;
    },
    handleUploadMetaCSVFile: function () {
      this.$refs.metainput.click();
    },
    onChangeMetaCSVFile: async function () {
      try {
        this.ttlCsvObj.meta = await readLocalCSV(this.$refs.metainput.files[0]);
        if (this.ttlCsvObj.meta.length === 0) {
          console.log("read err");
          return;
        }
        this.csvFileObj.meta = await this.createFileBlobObj(
          this.$refs.metainput.files[0]
        );
        const metaArray = this.ttlCsvObj.meta;
        // console.log(metaArray[1]);
        metaArray[0].forEach((value, index) => {
          if (value === "語彙の名称") {
            this.base.title.ja = metaArray[1][index];
          } else if (value === "語彙の英語名称") {
            this.base.title.en = metaArray[1][index];
          } else if (value === "語彙の説明") {
            this.base.description.ja = metaArray[1][index];
          } else if (value === "語彙の英語説明") {
            this.base.description.en = metaArray[1][index];
          } else if (value === "語彙の作成者") {
            this.base.published = metaArray[1][index];
          } else if (value === "バージョン") {
            this.base.note = metaArray[1][index];
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    onChangeFileInput: async function (i, e) {
      try {
        const distribution = this.distributions[i];
        if (distribution.type === "file") {
          distribution.file = await this.createFileBlobObj(e);
        } else if (distribution.type === "meta") {
          this.csvFileObj.vocab = await this.createFileBlobObj(e);
          this.ttlCsvObj.vocab = await readLocalCSV(e);
          const blob = await this.convertJsonLdBlob();
          const fileName =
            "vocab_" +
            generateApiKey({
              method: "string",
              pool: "abcdefghijklmnopqrstuvwxyz",
              length: 6,
            }) +
            "_jsonld.json";
          distribution.file = {
            name: fileName,
            data: await this.getBase64(blob),
          };
        }
      } catch (err) {
        console.log(err);
        this.distributions[i].file = new Object();
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async convertJsonLdBlob() {
      if (
        this.ttlCsvObj.meta.length === 0 ||
        this.ttlCsvObj.vocab.length === 0
      ) {
        return null;
      }
      const jsonld = await createJsonLd([
        this.ttlCsvObj.meta,
        this.ttlCsvObj.vocab,
      ]);
      return new Blob([JSON.stringify(jsonld, null, 2)], {
        type: "text/plain",
      });
    },
    async createFileBlobObj(file) {
      const blob = await this.getBase64(file);
      return {
        name: file.name,
        data: blob,
      };
    },
    saveBtnPressed: async function () {
      if (this.loading) {
        return;
      }
      if (this.$refs.form.validate()) {
        // console.log("OK " + this.isNew);
        if (this.distributions.length === 0) {
          console.log("NG");
          return;
        }
      } else {
        return;
      }
      this.loading = true;
      this.snackbar = false;
      if (this.isNew) {
        const identifier = await this.saveNewOnDB();
        if (this.distributions.length > 0) {
          updateVocabularyStructure(identifier, "all", true, true);
        }
        this.snackbar = true;
        this.loading = false;
        const data = await getUserVocabulariesList();
        this.$store.dispatch("commitVocabularies", data);
        this.$store.dispatch("commitEditParams", {
          mode: "vocabularies",
          id: identifier,
        });
        this.$router.push({ name: "Edit" }).catch((err) => console.log(err));
      } else {
        await this.saveOverOnDB();
        this.snackbar = true;
        this.loading = false;
        const data = await getUserVocabulariesList();
        this.$store.dispatch("commitVocabularies", data);
        await this.initialize();
        if (this.distributions.length > 0) {
          updateVocabularyStructure(this.$store.state.editParams.id, "all", true, true);
        }
      }
    },
    saveNewOnDB: async function () {
      const newDoc = db.collection("vocabularies").doc();
      const rNewDoc = db.collection("revisions").doc();

      const uid = getUserID();
      if (this.editors.filter((id) => id === uid).length === 0) {
        this.editors.push(uid);
      }
      this.app_metadata.creator = uid;
      this.app_metadata.lastEditor = uid;
      this.app_metadata.previousVersion = 0;
      this.app_metadata.users = this.editors;
      this.app_metadata.status = this.app_status.selected;
      if (this.namespace.use) {
        this.app_metadata.namespace = this.namespace.path;
      } else {
        delete this.app_metadata.namespace;
      }

      // メタCSVを読み込んでいた場合 ファイルのアップロード
      if (this.csvFileObj.meta.name) {
        const url = await fileUploadToRevision(
          this.csvFileObj.meta,
          rNewDoc.id
        );
        this.app_metadata.specializedObject = {
          meta_csv_url: url,
        };
      }

      const dict = VocabUtil.transformMetaToDCAT(
        this.base,
        this.contacts,
        this.app_metadata,
        newDoc.id,
        "create"
      );

      const self = this;

      const batch = db.batch();
      batch.set(newDoc, dict);
      batch.set(rNewDoc, dict);

      await Promise.all(
        this.distributions.map(async (item) => {
          const finalItem = item;
          const distVocRef = newDoc.collection("dcat:distribution").doc();
          const distRevRef = rNewDoc
            .collection("dcat:distribution")
            .doc(distVocRef.id);
          finalItem.metadata.id = distVocRef.id;
          finalItem.metadata.lastEditor = uid;
          finalItem.metadata.creator = uid;
          //ボキャブラリー用のCSVを読み込んでいる。CSVとJSONLDの両方をアップロード
          if (item.type === "meta" && self.csvFileObj.vocab.name) {
            const url = await fileUploadToRevisionOfDisribution(this.csvFileObj.vocab, rNewDoc.id, distRevRef.id);
            finalItem.metadata.specializedObject = {
              vocabulary_csv_url: url,
            };
            const url2 = await fileUploadToRevisionOfDisribution(item.file, rNewDoc.id, distRevRef.id);
            finalItem.downloadURL = url2;
          } else if (item.type === "file") {
            const url2 = await fileUploadToRevisionOfDisribution(item.file, rNewDoc.id, distRevRef.id);
            finalItem.downloadURL = url2;
          }
          const dcatObj = VocabUtil.transformDistributionToDCAT(
            finalItem,
            "create"
          );
          batch.set(distVocRef, dcatObj);
          batch.set(distRevRef, dcatObj);
        })
      );

      await batch.commit();
      return newDoc.id;
    },
    saveOverOnDB: async function () {
      // Revisionsには新しく作成
      const rNewDoc = db.collection("revisions").doc();
      const oneDoc = db
        .collection("vocabularies")
        .doc(this.$store.state.editParams.id);

      const uid = getUserID();
      if (this.editors.filter((id) => id === uid).length === 0) {
        this.editors.push(uid);
      }
      this.app_metadata.lastEditor = uid;
      this.app_metadata.previousVersion = this.app_metadata.previousVersion + 1;
      this.app_metadata.users = this.editors;
      this.app_metadata.status = this.app_status.selected;
      if (this.namespace.use) {
        this.app_metadata.namespace = this.namespace.path;
      } else {
        delete this.app_metadata.namespace;
      }

      // メタCSVを読み込んでいた場合 ファイルのアップロード
      if (this.csvFileObj.meta.name) {
        const url = await fileUploadToRevision(
          this.csvFileObj.meta,
          rNewDoc.id
        );
        if (this.app_metadata.specializedObject) {
          this.app_metadata.specializedObject.meta_csv_url = url;
        } else {
          this.app_metadata.specializedObject = {
            meta_csv_url: url,
          };
        }
      }
      //  else {
      //   delete this.app_metadata.specializedObject;
      // }

      const dict = VocabUtil.transformMetaToDCAT(
        this.base,
        this.contacts,
        this.app_metadata,
        oneDoc.id,
        "update"
      );

      const self = this;

      const batch = db.batch();
      batch.set(oneDoc, dict);
      batch.set(rNewDoc, dict);

      // Revisionsには新しくコピーするので全Distributionに対して処理は必要
      await Promise.all(
        this.distributions.map(async (item) => {
          const finalItem = item;
          const distVocRef =
            item.metadata.id !== ""
              ? oneDoc.collection("dcat:distribution").doc(item.metadata.id)
              : oneDoc.collection("dcat:distribution").doc();
          const distRevRef = rNewDoc
            .collection("dcat:distribution")
            .doc(distVocRef.id);

          if (item.metadata.id === "") {
            finalItem.metadata.id = distVocRef.id;
            finalItem.metadata.creator = uid;
          }
          finalItem.metadata.lastEditor = uid;
          // delete finalItem.metadata.specializedObject;

          //ボキャブラリー用のCSVを読み込んでいる。CSVとJSONLDの両方をアップロード
          if (item.type === "meta" && self.csvFileObj.vocab.name) {
            const url = await fileUploadToRevisionOfDisribution(
              this.csvFileObj.vocab,
              rNewDoc.id,
              distRevRef.id
            );
            if (finalItem.metadata.specializedObject) {
              finalItem.metadata.specializedObject.vocabulary_csv_url = url;
            } else {
              finalItem.metadata.specializedObject = {
                vocabulary_csv_url: url,
              };
            }
            const url2 = await fileUploadToRevisionOfDisribution(item.file, rNewDoc.id, distRevRef.id);
            finalItem.downloadURL = url2;
          } else if (item.type === "file") {
            const url2 = await fileUploadToRevisionOfDisribution(item.file, rNewDoc.id, distRevRef.id);
            finalItem.downloadURL = url2;
          }
          const dcatObj = VocabUtil.transformDistributionToDCAT(
            finalItem,
            "update"
          );
          batch.set(distVocRef, dcatObj);
          batch.set(distRevRef, dcatObj);
          return true;
        })
      );

      // Distributionの削除、１つ前のPlomise.allでは更新だけなので削除されない
      this.removeDistributions.forEach((item) => {
        if (item.metadata.id !== "") {
          const dictVocDoc = oneDoc
            .collection("dcat:distribution")
            .doc(item.metadata.id);
          batch.delete(dictVocDoc);
        }
      });

      await batch.commit();
    },
    historyBtnPressed() {
      this.getHistory();
    },
    callGetVocabularyHistories: async function () {
      this.histories = await getVocabularyHistories(
        this.$store.state.editParams.id
      );
    },
    callGetUserList: async function () {
      const userList = await getUserList();
      if (userList.data.length > 0) {
        this.users = userList.data.map((user) => {
          const flag = this.editors.find((uid) => user.uid === uid);
          return {
            uid: user.uid,
            name: user.name,
            mail: user.mail,
            member: flag ? true : false,
            creator: this.app_metadata.creator === user.uid,
          };
        });
      }
    },
    callGetAvailableNamespaces: async function () {
      this.namespace.list = await getAvailableNamespaces();
      this.namespaceRefresh = false;
    },
    editorsChange: function (user) {
      if (user.member) {
        this.editors = this.editors.filter((uid) => uid !== user.uid);
        user.member = false;
      } else {
        this.editors.push(user.uid);
        user.member = true;
      }
    },
    getDate(fDate) {
      return moment(fDate.toDate()).format("YYYY/MM/DD hh:mm:ss");
    },
    async distDownload(v) {
      if (v.metadata && v.metadata.id !== "") {
        const text = await getDistributionFile(this.$store.state.editParams.id, v.metadata.id);
        const blob = new Blob([text], {
          type: "text/plain",
        });
        const fileName = VocabUtil.createFileNameFromUrl(v.downloadURL);
        this.downloadByATag(blob, fileName);
      }
    },
    downloadByATag(blob, fileName) {
      const link = document.createElement("a");
      if (window.navigator.msSaveOrOpenBlob) {
        /* msSaveOrOpenBlobの場合はファイルを保存せずに開ける */
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.href = url;
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.file_upload {
  display: none;
}
.dist_divider {
  height: 3px;
  background: #666;
  margin: 3px 0;
}
.dist_divider2 {
  height: 3px;
  background: #c6c6c6;
  margin: 3px 0;
}
</style>
